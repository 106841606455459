import {
  USDT_ADDRESS_L1,
  USDT_ADDRESS_L2,
  BTC_ADDRESS_L1,
  BTC_ADDRESS_L2,
  ETH_ADDRESS_L1,
  ETH_ADDRESS_L2,
  BUSD_ADDRESS_L1,
  BUSD_ADDRESS_L2,
  USDC_ADDRESS_L1,
  USDC_ADDRESS_L2,
  DAI_ADDRESS_L1,
  DAI_ADDRESS_L2,
  NET_ENV,
  COMBO_ADDRESS_L2,
  COMBO_ADDRESS_L1,
} from '../env';
import ABI from '../base/tokenABI/tokenABI.json';

export const bep20TokenList =
  NET_ENV === 'Mainnet'
    ? [
        {
          name: 'USDT',
          symbol: 'USDT',
          logoURI:
            'https://raw.githubusercontent.com/ComboLabs/ComboTokenList/master/data/USDT/logo.png',
          decimals: '18',
          l1Address: USDT_ADDRESS_L1,
          l2Address: USDT_ADDRESS_L2,
          ABI: ABI,
          extensions: {
            officialBridgeStatus: '',
          },
        },
        {
          name: 'COMBO',
          symbol: 'COMBO',
          logoURI: '',
          decimals: '18',
          l1Address: COMBO_ADDRESS_L1,
          l2Address: COMBO_ADDRESS_L2,
          ABI: ABI,
          extensions: {
            officialBridgeStatus: '',
          },
        },
      ]
    : [
        {
          name: 'USDT',
          symbol: 'USDT',
          logoURI: '',
          decimals: '18',
          l1Address: USDT_ADDRESS_L1,
          l2Address: USDT_ADDRESS_L2,
          ABI: ABI,
          extensions: {
            officialBridgeStatus: '',
          },
        },
        {
          name: 'BTC',
          symbol: 'BTC',
          logoURI: '',
          decimals: '18',
          l1Address: BTC_ADDRESS_L1,
          l2Address: BTC_ADDRESS_L2,
          ABI: ABI,
          extensions: {
            officialBridgeStatus: '',
          },
        },
        {
          name: 'ETH',
          symbol: 'ETH',
          logoURI: '',
          decimals: '18',
          l1Address: ETH_ADDRESS_L1,
          l2Address: ETH_ADDRESS_L2,
          ABI: ABI,
          extensions: {
            officialBridgeStatus: '',
          },
        },
        {
          name: 'BUSD',
          symbol: 'BUSD',
          logoURI: '',
          decimals: '18',
          l1Address: BUSD_ADDRESS_L1,
          l2Address: BUSD_ADDRESS_L2,
          ABI: ABI,
          extensions: {
            officialBridgeStatus: '',
          },
        },
        {
          name: 'USDC',
          symbol: 'USDC',
          logoURI: '',
          decimals: '18',
          l1Address: USDC_ADDRESS_L1,
          l2Address: USDC_ADDRESS_L2,
          ABI: ABI,
          extensions: {
            officialBridgeStatus: '',
          },
        },
        {
          name: 'DAI',
          symbol: 'DAI',
          logoURI: '',
          decimals: '18',
          l1Address: DAI_ADDRESS_L1,
          l2Address: DAI_ADDRESS_L2,
          ABI: ABI,
          extensions: {
            officialBridgeStatus: '',
          },
        },
      ];
